/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import React, { useState } from 'react';
import {
  Table, TableRow, TableCell, TableHead, TableBody, Grid, IconButton, Accordion, AccordionSummary, AccordionDetails,
  Link,
  Stack,
  Box,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useMeasure } from 'react-use';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';
import {
  formatDistance, formatHours, formatMsecToHours, formatNumericHours, formatSpeed, formatTime, formatVolume,
  formatVolumeNumeric,
} from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import ReportFilter from './components/ReportFilter';
import usePersistedState from '../common/util/usePersistedState';
import ColumnSelect from './components/ColumnSelect';
import { useCatch, useEffectAsync } from '../reactHelper';
import useReportStyles from './common/useReportStyles';
import TableShimmer from '../common/components/TableShimmer';
import { useAttributePreference, usePreference } from '../common/util/preferences';
import HeavyDutyCard from '../common/components/HeavyDutyCard';
import HeavyDutyCardShimmer from '../common/components/HeavyDutyCardShimmer';
import { prefixString } from '../common/util/stringUtils';
import MapView from '../map/core/MapView';
import MapGeofence from '../map/MapGeofence';
import MapPositions from '../map/MapPositions';
import MapCamera from '../map/MapCamera';
import PositionValue from '../common/components/PositionValue';
import VirtualizedTable from './components/VirtualizedTable';
import VirtualizedTableShimmer from '../common/components/VirtualizedTableShimmer';
import CorporateReportFilter from './components/CorporateReportFilter';
import palette from '../common/theme/palette';

const columnsArray = [
  ['hours', 'reportEngineHours'],
  ['fuel_consumption', 'reportSpentFuel'],
  ['distance', 'sharedDistance'],
  ['alarmHighRpm', 'alarmHighRpm'],
  ['alarmTemperature', 'alarmTemperature'],
  ['alarmHighOilPressure', 'alarmHighOilPressure'],
];
const top5ColumnsArray = [

  ['branch', 'reportBranch'],
  ['construction', 'reportConstruction'],
  ['project', 'reportProject'],
];
const columnsMap = new Map([...columnsArray, ...top5ColumnsArray]);

const eventColumnsArray = [
  ['eventTime', 'positionFixTime'],
  ['type', 'sharedType'],
  ['attributes', 'commandData'],
];
const eventColumnsMap = new Map(eventColumnsArray);

const CorporatePage = () => {
  const classes = useReportStyles();
  const t = useTranslation();

  const devices = useSelector((state) => state.devices.items);
  const geofences = useSelector((state) => state.geofences.items);
  const groups = useSelector((state) => state.groups.items);

  const distanceUnit = useAttributePreference('distanceUnit');
  const speedUnit = useAttributePreference('speedUnit');
  const volumeUnit = useAttributePreference('volumeUnit');
  const hours12 = usePreference('twelveHourFormat');

  const [columns, setColumns] = usePersistedState('corpotateColumns', ['hours', 'fuel_consumption', 'distance']);
  const [top5Columns, setTop5Columns] = usePersistedState('corpotateTop5Columns', ['branch', 'construction', 'project']);
  const eventColumns = ['eventTime', 'type', 'attributes'];
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [position, setPosition] = useState(null);

  const [openDetails, setOpenDetails] = useState(false);

  const formatValue = (item, key) => {
    switch (key) {
      case 'startTime':
      case 'endTime':
        return formatTime(item[key], 'minutes', hours12);
      case 'duration':
        return formatHours(item[key]);
      case 'engineHours':
      case 'hours':
        return formatNumericHours(item[key], t);
      case 'spentFuel':
      case 'fuel_consumption':
        return formatVolume(item[key], volumeUnit, t, 0);
      case 'pumpedMaterial':
        return `${Math.round(item.production_value) || 0} ${t('sharedCubicMeterAbbreviation')}`;
      case 'distance':
        return formatDistance(item[key], distanceUnit, t);
      case 'type':
        return t(prefixString('event', item[key]));
      case 'attributes':
        switch (item.type) {
          case 'alarm':
            return t(prefixString('alarm', item.attributes.alarm));
          case 'deviceOverspeed':
            return formatSpeed(item.attributes.speed, speedUnit, t);
          case 'deviceFuelIncrease':
            return formatVolume(item.attributes.volume, volumeUnit, t, 0);
          default:
            return '';
        }
      default:
        return item[key];
    }
  };

  const formattedItems = [...items].map((item) => ({
    ...item,
    name: devices[item.deviceid].name,
    engineHours: formatMsecToHours(parseInt((item.hours || 0), 10)),
    spentFuel: formatVolumeNumeric(item.fuel_consumption, volumeUnit),
  }));

  const sortedItems = (list, key) => [...list].sort((a, b) => (parseInt(b[key], 10) || 0) - (parseInt(a[key], 10) || 0));
  const filterItems = (list, key, value) => [...list].filter((item) => item[key] === value);
  const truncateItems = (list, count) => [...list].slice(0, count);

  const engineHoursChartSettings = {
    yAxis: [
      {
        /* label: t('reportEngineHours'), */
        tickNumber: 4,
      },
    ],
    xAxis:
      [{
        scaleType: 'band',
        dataKey: 'name',
        type: 'category',
        tickSize: 4,
        tickPlacement: 'middle',
        tickLabelPlacement: 'middle',
        tickLabelInterval: (_value, _index) => true,
        tickLabelStyle: {
          fontSize: '8px',
          transform: 'rotateZ(-45deg) translateX(-5px) translateY(-5px)',
          textAnchor: 'end',
        },
        valueFormatter: (v, c) => {
          const name = v.toUpperCase();
          if (c.location === 'tick') {
            return name.length > 10 ? `${name.slice(0, 10)}...` : name;
          }
          return name;
        },
      }],
  };
  const spentFuelChartSettings = {
    yAxis: [
      {
        /* label: t('reportEngineHours'), */
        tickNumber: 4,
      },
    ],
    xAxis:
      [{
        scaleType: 'band',
        dataKey: 'name',
        type: 'category',
        tickSize: 4,
        tickPlacement: 'middle',
        tickLabelPlacement: 'middle',
        tickLabelInterval: (_value, _index) => true,
        tickLabelStyle: {
          fontSize: '8px',
          transform: 'rotateZ(-45deg) translateX(-5px) translateY(-5px)',
          textAnchor: 'end',
        },
        valueFormatter: (v, c) => {
          const name = v.toUpperCase();
          if (c.location === 'tick') {
            return name.length > 10 ? `${name.slice(0, 10)}...` : name;
          }
          return name;
        },
      }],
  };

  const totalPumpedMaterial = Math.round([...items].reduce((acc, item) => (item.production_unit === 'pumpedMaterial' ? acc + item.production_value : acc), 0));
  const totalSpentFuel = [...items].reduce((acc, item) => acc + item.fuel_consumption, 0);
  const totalEngineHours = [...items].reduce((acc, item) => {
    const hours = parseInt(item.hours, 10);
    if (Number.isNaN(hours)) return acc;
    return hours + acc;
  }, 0);

  useEffectAsync(async () => {
    if (selectedItem) {
      const response = await fetch(`/api/positions?id=${selectedItem.positionId}`);
      if (response.ok) {
        const positions = await response.json();
        if (positions.length > 0) {
          setPosition(positions[0]);
        }
      } else {
        throw Error(await response.text());
      }
    } else {
      setPosition(null);
    }
  }, [selectedItem]);

  const handleSubmit = useCatch(async ({ from, to, branchIds, projectIds, constructionIds }) => {
    setLoading(true);
    let groupIds = [];
    if (constructionIds.length > 0) {
      groupIds = constructionIds;
    } else if (projectIds.length > 0) {
      groupIds = projectIds;
    } else if (branchIds.length > 0) {
      groupIds = branchIds;
    }
    try {
      const query = new URLSearchParams({ from, to, tablename: 'heavy_duty' });
      groupIds.forEach((groupId) => query.append('groupId', groupId));
      const response = await fetch(`/api/analytics?${query}`);
      if (response.ok) {
        const data = await response.json();
        setItems(data);
        console.log(data);
      } else {
        const data = await response.text();
        throw Error(data);
      }
    } finally {
      setLoading(false);
    }
  });

  const [layoutRef, { width: layoutWidth }] = useMeasure();

  const headers = () => (
    <TableRow className={classes.tableHeader}>
      <TableCell sx={{ px: 0, pl: 2 }}>{t('sharedDevice')}</TableCell>
      {columns.map((key) => (<TableCell key={key} sx={{ px: 1 }}>{t(columnsMap.get(key))}</TableCell>))}
    </TableRow>
  );
  const rowContent = (_index, row) => (
    <>
      <TableCell width={160} sx={{ pl: 2, py: 0 }}>{devices[row.deviceid].name}</TableCell>
      {columns.map((key) => (
        <TableCell key={key} sx={{ p: 0, px: 1, textAlign: 'start', minWidth: { xs: 100 } }}>
          {formatValue(row, key)}
        </TableCell>
      ))}
    </>
  );
  const rowShimmer = () => (
    <VirtualizedTableShimmer columns={columns.length + 1} startAction />
  );
  // eslint-disable-next-line react/no-unstable-nested-components
  const top5Headers = (key) => () => (
    <TableRow className={classes.tableHeader}>
      <TableCell sx={{ p: 0, pl: 2 }}>{t('sharedDevice')}</TableCell>
      <TableCell sx={{ p: 0, pl: 2 }}>{t(key)}</TableCell>
      {top5Columns.map((key) => (<TableCell key={key} sx={{ px: 1 }}>{t(columnsMap.get(key))}</TableCell>))}
    </TableRow>
  );
  // eslint-disable-next-line react/no-unstable-nested-components
  const top5RowContent = (key, valueGetter) => (_index, row) => {
    const { deviceid } = row;
    const device = devices[deviceid];
    const construction = groups[device.groupId];
    const project = groups[construction.groupId];
    const branch = groups[project.groupId];
    row.branch = branch.name;
    row.project = project.name;
    row.construction = construction.name;
    // eslint-disable-next-line react/destructuring-assignment
    row[key] = row[valueGetter];
    return (
      <>
        <TableCell sx={{ px: 2, py: 0, minWidth: { xs: 100 } }}>{devices[deviceid].name}</TableCell>
        <TableCell sx={{ px: 2, py: 0, minWidth: { xs: 100 } }}>{formatValue(row, key)}</TableCell>
        {top5Columns.map((key) => (
          <TableCell key={key} sx={{ p: 0, px: 1, textAlign: 'start', minWidth: { xs: 80 } }}>
            {formatValue(row, key)}
          </TableCell>
        ))}
      </>
    );
  };

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle']}>
      <div className={classes.container} ref={layoutRef}>
        {selectedItem && (
          <div className={classes.containerMapSticky}>
            <MapView>
              <MapGeofence />
              {position && <MapPositions positions={[position]} titleField="fixTime" />}
            </MapView>
            {position && <MapCamera latitude={position.latitude} longitude={position.longitude} />}
          </div>
        )}
        <div className={classes.header}>
          <CorporateReportFilter
            handleSubmit={handleSubmit}
            ignoreDevice
            includeGroups={['branch', 'project', 'construction']}
            deviceType="heavy-duty"
            isDaily
            showOnly
          >
            {/* <ColumnSelect columns={columns} setColumns={setColumns} columnsArray={columnsArray} /> */}
          </CorporateReportFilter>
        </div>
        <div className={classes.containerMain}>
          <Grid container spacing={2} sx={{ flex: 1, p: 2 }}>
            <Grid item container xs={12} md={5} spacing={2}>
              {/* TABLA */}
              <Grid item xs={12} sx={{ p: 2 }}>
                <Box sx={{ height: '400px' }}>
                  <VirtualizedTable
                    columns={headers}
                    rowContent={!loading && items.length > 0 ? rowContent : rowShimmer}
                    rows={!loading && items.length > 0 ? items : [...Array(5)]}
                  />
                </Box>
              </Grid>
              {/* TOTALES */}
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={4}>
                  <Box sx={{ p: 1.5, borderRadius: 4, bgcolor: palette.primary.main }}>
                    <Typography variant="body2" color={palette.primary.contrastText}>
                      {`${t('reportTotal')} ${t('reportPumpedMaterial')}`}
                    </Typography>
                    <Typography variant="h6" color={palette.primary.contrastText}>
                      {`${totalPumpedMaterial} ${t('sharedCubicMeterAbbreviation')}`}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box sx={{ p: 1.5, borderRadius: 4, bgcolor: palette.primary.main }}>
                    <Typography variant="body2" color={palette.primary.contrastText}>
                      {`${t('reportTotal')} ${t('reportSpentFuel')}`}
                    </Typography>
                    <Typography variant="h6" color={palette.primary.contrastText}>
                      {formatVolume(totalSpentFuel, volumeUnit, t, 0)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box sx={{ p: 1.5, borderRadius: 4, bgcolor: palette.primary.main }}>
                    <Typography variant="body2" color={palette.primary.contrastText}>
                      {`${t('reportTotal')} ${t('reportEngineHours')}`}
                    </Typography>
                    <Typography variant="h6" color={palette.primary.contrastText}>
                      {formatNumericHours(totalEngineHours, t)}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {/* CHARTS */}
            <Grid item xs={12} md={7} spacing={2}>
              <Box sx={{ height: '240px' }}>
                <BarChart
                  dataset={sortedItems(formattedItems, 'hours') || null}
                  series={[
                    {
                      dataKey: 'engineHours',
                      label: t(columnsMap.get('hours')),
                      valueFormatter: (v, c) => {
                        if (c.dataIndex < 0) return '';
                        const { hours } = sortedItems(formattedItems, 'hours')[c.dataIndex];
                        return formatNumericHours(hours, t);
                      },
                      color: palette.primary.main,
                    },
                  ]}
                  {...engineHoursChartSettings}
                  margin={{ top: 40, right: 10, bottom: 60, left: 40 }}
                  grid={{ horizontal: true }}
                />
              </Box>
              <Box sx={{ height: '240px', mt: 2 }}>
                <BarChart
                  dataset={sortedItems(formattedItems, 'spentFuel') || null}
                  series={[
                    {
                      dataKey: 'spentFuel',
                      label: t(columnsMap.get('fuel_consumption')),
                      valueFormatter: (v, c) => {
                        if (c.dataIndex < 0) return '';
                        const { fuel_consumption: spentFuel } = sortedItems(formattedItems, 'spentFuel')[c.dataIndex];
                        return formatVolume(spentFuel, volumeUnit, t);
                      },
                      color: palette.primary.main,
                    },
                  ]}
                  {...spentFuelChartSettings}
                  margin={{ top: 40, right: 10, bottom: 60, left: 40 }}
                  grid={{ horizontal: true }}
                />
              </Box>
            </Grid>
            {/* TOP 5 */}
            <Grid item xs={12} container spacing={2} sx={{ p: 2 }}>
              <Grid item xs={12} md={4} spacing={2}>
                <Typography
                  sx={{ bgcolor: palette.primary.main, color: palette.primary.contrastText, px: 2, my: 1, borderRadius: 4 }}
                >
                  {`Top 5 ${t('reportPumpedMaterial')}`}
                </Typography>
                <Box sx={{ height: '200px' }}>
                  <VirtualizedTable
                    columns={top5Headers('reportPumpedMaterial')}
                    rowContent={!loading && items.length > 0 ? top5RowContent('pumpedMaterial', 'production_value') : rowShimmer}
                    rows={!loading && items.length > 0 ?
                      truncateItems(sortedItems(filterItems(items, 'production_unit', 'pumpedMaterial'), 'production_value'), 5)
                      :
                      [...Array(5)]}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4} spacing={2}>
                <Typography
                  sx={{ bgcolor: palette.primary.main, color: palette.primary.contrastText, px: 2, my: 1, borderRadius: 4 }}
                >
                  {`Top 5 ${t('reportSpentFuel')}`}
                </Typography>
                <Box sx={{ height: '200px' }}>
                  <VirtualizedTable
                    columns={top5Headers('reportSpentFuel')}
                    rowContent={!loading && items.length > 0 ? top5RowContent('spentFuel', 'fuel_consumption') : rowShimmer}
                    rows={!loading && items.length > 0 ?
                      truncateItems(sortedItems([...items], 'fuel_consumption'), 5)
                      :
                      [...Array(5)]}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4} spacing={2}>
                <Typography
                  sx={{ bgcolor: palette.primary.main, color: palette.primary.contrastText, px: 2, my: 1, borderRadius: 4 }}
                >
                  {`Top 5 ${t('reportEngineHours')}`}
                </Typography>
                <Box sx={{ height: '200px' }}>
                  <VirtualizedTable
                    columns={top5Headers('reportEngineHours')}
                    rowContent={!loading && items.length > 0 ? top5RowContent('engineHours', 'hours') : rowShimmer}
                    rows={!loading && items.length > 0 ?
                      truncateItems(sortedItems([...items], 'hours'), 5)
                      :
                      [...Array(5)]}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </PageLayout>
  );
};

export default CorporatePage;
