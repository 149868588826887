/* eslint-disable react/destructuring-assignment */
import {
  FormControl, FormHelperText, InputLabel, ListSubheader, MenuItem, Select,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useEffectAsync } from '../../reactHelper';
import palette from '../theme/palette';

const GroupItems = (items) => {
  const branches = items.filter((item) => item.attributes.type === 'branch');
  const projects = items.filter((item) => item.attributes.type === 'project');
  const constructions = items.filter((item) => item.attributes.type === 'construction');

  const renderConstruction = (project) => {
    const projectConstructions = constructions.filter((item) => item.groupId === project.id);
    return projectConstructions.map((construction) => (
      <MenuItem key={construction.id} value={construction.id} role="option">{construction.name}</MenuItem>
    ));
  };

  const renderProject = (branch) => {
    const branchProjects = projects.filter((item) => item.groupId === branch.id);
    return branchProjects.map((project) => [
      <ListSubheader sx={{ bgcolor: palette.secondary.light, top: 32, zIndex: 9 }}>
        <Typography variant="subtitle1" sx={{ color: palette.primary.contrastText }}>
          {project.name}
        </Typography>
      </ListSubheader>,
      renderConstruction(project),
    ]);
  };

  const renderBranch = (branch) => (
    <ListSubheader sx={{ bgcolor: palette.primary.main, height: 32, zIndex: 10 }}>
      <Typography variant="h6" sx={{ color: palette.primary.contrastText }}>
        {branch.name}
      </Typography>
    </ListSubheader>
  );

  return (
    branches.map((branch) => [
      renderBranch(branch),
      renderProject(branch),
    ]));
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectIcon: {
    position: 'relative',
  },
}));

const SelectField = ({
  label,
  fullWidth,
  multiple,
  value,
  emptyValue = 0,
  emptyTitle = '\u00a0',
  onChange,
  endpoint,
  data,
  keyGetter = (item) => item.id,
  titleGetter = (item) => item.name,
  helperText,
  filterKey,
  filterValue,
  endadornment,
}) => {
  const classes = useStyles();
  const [items, setItems] = useState(data);

  useEffectAsync(async () => {
    if (endpoint) {
      const response = await fetch(endpoint);
      if (response.ok) {
        setItems(await response.json());
      } else {
        throw Error(await response.text());
      }
    }
  }, []);
  if (items) {
    return (
      <FormControl fullWidth={fullWidth}>
        <InputLabel>{label}</InputLabel>
        <Select
          label={label}
          multiple={multiple}
          value={value}
          onChange={onChange}
          classes={{ icon: classes.selectIcon }}
          endAdornment={endadornment}
        >
          {!multiple && emptyValue !== null && (
            <MenuItem value={emptyValue}>{emptyTitle}</MenuItem>
          )}
          {
            filterKey && filterValue ?
              GroupItems(items)
              :
              items.map((item) => (
                <MenuItem key={keyGetter(item)} value={keyGetter(item)}>{titleGetter(item)}</MenuItem>
              ))
          }
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    );
  }
  return null;
};

export default SelectField;
